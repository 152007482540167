var swiper = new Swiper(".swiper-container", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  clickable: true,
  breakpoints: {
    1024: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    640: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
  },
});

function humbergers() {
  var forEach = function (t, o, r) {
    if ("[object Object]" === Object.prototype.toString.call(t))
      for (var c in t)
        Object.prototype.hasOwnProperty.call(t, c) && o.call(r, t[c], c, t);
    else for (var e = 0, l = t.length; l > e; e++) o.call(r, t[e], e, t);
  };

  var hamburgers = document.querySelectorAll(".hamburger");
  if (hamburgers.length > 0) {
    forEach(hamburgers, function (hamburger) {
      hamburger.addEventListener(
        "click",
        function () {
          this.classList.toggle("is-active");
        },
        false
      );
    });
  }
}

humbergers();

$(function () {
  $(".hamburger").on("click", function () {
    $(".navbar-collapse").toggleClass("navbar-collapse-active");
  });
});

//Get the button
var fixedBlock = document.getElementById("fixed_block");
var footer = document.querySelector("footer");
fixedBlock.style.opacity = "0";
fixedBlock.style.bottom = "-70px";
fixedBlock.style.visibility = "hidden";

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function () {
  scrollFunction();
};

function scrollFunction() {
  if (
    (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) &&
    document.documentElement.scrollTop < footer.offsetTop - 1000
  ) {
    fixedBlock.style.opacity = "1";
    fixedBlock.style.bottom = "0px";
    fixedBlock.style.visibility = "visible";
  } else {
    fixedBlock.style.opacity = "0";
    fixedBlock.style.bottom = "-70px";
    fixedBlock.style.visibility = "hidden";
  }
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

// (function ($) {
//   function mediaSize() {
//       if (window.matchMedia('(max-width: 767px)').matches) {
//           $('#logo').attr('src', 'img/logotip_mob.png');

//       } else {
//         $('#logo').attr('src', 'img/logotip.png');
//       }
//   };

//   mediaSize();
//   window.addEventListener('resize', mediaSize, true);
//   window.addEventListener('load', mediaSize, true);

// })(jQuery);

$(function () {
  if (!$(".news-content__full table").parent().hasClass("table-responsive")) {
    $(".news-content__full table").wrap("<div class='table-responsive'></div>");
  }
  $(".news-content__full table").addClass("table table-striped table-bordered");

  $(
    ".news-content__full table, .news-content__full table tr, .news-content__full td, .news-content__full table th, .news-content__full table p, .news-content__full table b, .news-content__full table strong, .news-content__full table u, .news-content__full table span"
  ).removeAttr("style valign width lang height border");
});

// ?modestbranding=1&showinfo=0&autoplay=1&loop=1&mute=1&controls=0
// $(document).ready(function () {
//   // $('.outer-news__media iframe, .outer-news__media video').attr(src, "")
//   var $j = jQuery.noConflict();
//   $j(".outer-news__media iframe, .outer-news__media video").prop("src", function (i, oldSrc) {
//     return (
//       oldSrc +
//       (oldSrc.indexOf("?")
//         ? "?modestbranding=1&showinfo=0&version=3&autoplay=1&loop=1&mute=1&controls=0"
//         : "?modestbranding=1&showinfo=0&version=3&autoplay=1&loop=1&mute=1&controls=0")
//     );
//   });
// });

////////////////////////////////////// News Video AutoPlay Mute Loop \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

////////////////////////////////////// Modal Video AutoPlay \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

(function ($) {
  function mediaSize() {
    if (window.matchMedia("(min-width: 1200px)").matches) {
      $(document).ready(function () {
        // $('.outer-news__media iframe, .outer-news__media video').attr(src, "")
        // var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
        var regExp = new RegExp(
          "/.*(?:youtu.be/|v/|u/w/|embed/|watch?v=)([^#&?]*).*/"
        );

        var url1 = $(".outer-news__media iframe").attr("src"),
          videoId1 = regExp.test(url1);

        var url2 = $(".bg-video iframe").attr("src"),
          videoId2 = regExp.test(url2);

        var url3 = $(".top-news iframe").attr("src"),
          videoId3 = regExp.test(url3);

        var url4 = $(".inner-news iframe").attr("src"),
          videoId4 = regExp.test(url4);
        // if (videoId && videoId[1].length === 11) {
        // }

        // var $j = jQuery.noConflict();
        $(".outer-news__media iframe").prop("src", function (i, oldSrc) {
          return (
            oldSrc +
            (oldSrc.indexOf("?")
              ? "?modestbranding=1&showinfo=0&autoplay=1&loop=1&mute=1&controls=0"
              : "?modestbranding=1&showinfo=0&autoplay=1&loop=1&mute=1&controls=0") +
            "&playlist=" +
            videoId1[1]
          );
        });
        $(".bg-video iframe").prop("src", function (i, oldSrc) {
          return (
            oldSrc +
            (oldSrc.indexOf("?")
              ? "?modestbranding=1&showinfo=0&autoplay=1&loop=1&mute=1&controls=0"
              : "?modestbranding=1&showinfo=0&autoplay=1&loop=1&mute=1&controls=0") +
            "&playlist=" +
            videoId2[1]
          );
        });
        $(".top-news iframe").prop("src", function (i, oldSrc) {
          return (
            oldSrc +
            (oldSrc.indexOf("?")
              ? "?modestbranding=1&showinfo=0&autoplay=1&loop=1&mute=1&controls=0"
              : "?modestbranding=1&showinfo=0&autoplay=1&loop=1&mute=1&controls=0") +
            "&playlist=" +
            videoId3[1]
          );
        });
        $(".inner-news iframe").prop("src", function (i, oldSrc) {
          return (
            oldSrc +
            (oldSrc.indexOf("?")
              ? "?modestbranding=1&showinfo=0&autoplay=1&loop=1&mute=1&controls=0"
              : "?modestbranding=1&showinfo=0&autoplay=1&loop=1&mute=1&controls=0") +
            "&playlist=" +
            videoId4[1]
          );
        });
      });

      $(document).ready(function () {
        $(".video-open__full").on("click", function () {
          var oldSrc = $(this)
            .parents(".news-item")
            .find(".bg-video iframe")
            .attr("src"); //Get the src of the iframe
          var newSrc = oldSrc.replace("mute=1", "mute=0"); //Replace "autoplay=1" by "autoplay=0"
          $(this)
            .parents(".news-item")
            .find(".bg-video iframe")
            .attr("src", newSrc); //Change the src attr to the new value

          $(this)
            .parents(".news-item")
            .find(".video-close")
            .addClass("is-active");

          $(this)
            .parents(".news-item")
            .find(".bg-video")
            .addClass("bg-video-active");

          $(this).parents(".news-item").addClass("news-item-active");

          //////////////////////////////////////// clickOutside \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
          jQuery.fn.clickOutside = function (callback) {
            var $me = this;
            $(document).mouseup(function (e) {
              if (!$me.is(e.target) && $me.has(e.target).length === 0) {
                callback.apply($me);
              }
            });
          };

          $(this)
            .parents(".news-item")
            .find(".bg-video")
            .clickOutside(function () {
              $(this).removeClass("bg-video-active"); // or `$(this).hide()`, if you must
              var oldSrc = $(this).find("iframe").attr("src"); //Get the src of the iframe
              var newSrc = oldSrc.replace("mute=0", "mute=1"); //Replace "autoplay=1" by "autoplay=0"
              $(this).find("iframe").attr("src", newSrc); //Change the src attr to the new value

              $(".video-close").removeClass("is-active");
              $(".news-item").removeClass("news-item-active");
            });
          // $("body").addClass("body-active");
        });
        $(".video-close").on("click", function () {
          var oldSrc = $(this)
            .parents(".news-item")
            .find(".bg-video iframe")
            .attr("src"); //Get the src of the iframe
          var newSrc = oldSrc.replace("mute=0", "mute=1"); //Replace "autoplay=1" by "autoplay=0"
          $(this)
            .parents(".news-item")
            .find(".bg-video iframe")
            .attr("src", newSrc); //Change the src attr to the new value

          $(this)
            .parents(".news-item")
            .find(".video-close")
            .removeClass("is-active");
          $(this)
            .parents(".news-item")
            .find(".bg-video")
            .removeClass("bg-video-active");
          $(this).parents(".news-item").removeClass("news-item-active");
          // $("body").removeClass("body-active");
        });
      });
    } else {
      function updateQueryStringParameter(uri, key, value) {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf("?") !== -1 ? "&" : "?";
        if (uri.match(re)) {
          return uri.replace(re, "$1" + key + "=" + value + "$2");
        } else {
          return uri + separator + key + "=" + value;
        }
      }
      $(".video-open-modal").on("click", function () {
        var url = $(".video-modal iframe").attr("src");
        var Updatedurl = updateQueryStringParameter(url, "autoplay", "true");
        $(".video-modal iframe").attr("src", Updatedurl);
      });

      $(".video-modal").on("hidden.bs.modal", function (e) {
        $(".video-modal iframe").attr(
          "src",
          $(".video-modal iframe").attr("src")
        );

        var url2 = $(".video-modal iframe").attr("src");
        var Updatedurl2 = updateQueryStringParameter(url2, "autoplay", "false");
        $(".video-modal iframe").attr("src", Updatedurl2);
      });

      $(".video-modal").on("hidden.bs.modal", function (e) {
        $(".video-modal video").attr(
          "src",
          $(".video-modal video").attr("src")
        );
      });
    }
  }

  mediaSize();
  window.addEventListener("resize", mediaSize, true);
  window.addEventListener("load", mediaSize, true);
})(jQuery);